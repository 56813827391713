let config = {
  appVersion: '',
  customerSpecific: {
    vlm: '',
    nmh: ''
  },
  features: '', // in config.json: either leave as '' or fill with an object e.g. { agency: "off" }
  core: {
    apiUrl: ''
  },
  contentBlockService: {
    apiUrl: ''
  },
  sso: {
    baseUrl: '',
    apiUrl: '',
    oidcManager: {
      authority: '',
      clientId: '',
      scope: '',
      responseType: '',
      responseMode: '',
      redirectUri: '',
      postLogoutRedirectUri: ''
    }
  },
  nativeCampaignManagement: {
    apiUrl: '',
    xApiKey: ''
  },
  don: {
    apiUrl: ''
  },
  edon: {
    apiUrl: ''
  },
  dam: {
    baseUrl: '',
    apiUrl: ''
  },
  covers: {
    baseUrl: ''
  },
  antares: {
    apiUrl: '',
    apiToken: ''
  },
  eshop: {
    apiUrl: ''
  },
  video: {
    apiUrl: ''
  },
  sport24: {
    apiUrl: ''
  },
  analytics: {
    apiUrl: ''
  },
  notificationService: {
    apiUrl: ''
  },
  dataHub: {
    apiUrl: '',
    xApiKey: {
      abn: '',
      realtime: '',
      hpctr: '',
      video: '',
      universalDashboarding: '',
      recommend: ''
    },
    recommend: {
      apiUrl: '',
      xApiKey: ''
    },
    editPerf: {
      apiUrl: '',
      xApiKey: ''
    }
  },
  aiService: {
    apiUrl: '',
    apiUrlV2: ''
  },
  linkPreview: {
    apiUrl: '',
    apiToken: ''
  },
  eagleVideoPlayer: {
    baseUrl: ''
  },
  articleSetting: {
    elasticArticleListing: '',
    articlePreviewUrl: '',
    articleLogInterval: ''
  },
  hotjar: {
    hotjarAccountId: ''
  },
  woodwingService: {
    apiUrl: ''
  }
}

// lazy loading
//   i.e. it's important to have these as functions due to the fact that the config.json file does not exist yet during the build
export default {
  setConfig (_config) {
    config = _config
  },
  appVersion: () => config.appVersion,
  environment: () => config.environment,
  customerSpecific: {
    vlm: () => config.customerSpecific.vlm,
    nmh: () => config.customerSpecific.nmh
  },
  features: () => config.features,
  core: {
    apiUrl: () => config.core.apiUrl
  },
  contentBlockService: {
    apiUrl: () => config.contentBlockService.apiUrl
  },
  sso: {
    baseUrl: () => config.sso.baseUrl,
    apiUrl: () => config.sso.apiUrl,
    oidcManager: {
      authority: () => config.sso.oidcManager.authority,
      clientId: () => config.sso.oidcManager.clientId,
      scope: () => config.sso.oidcManager.scope,
      responseType: () => config.sso.oidcManager.responseType,
      responseMode: () => config.sso.oidcManager.responseMode,
      redirectUri: () => config.sso.oidcManager.redirectUri,
      postLogoutRedirectUri: () => config.sso.oidcManager.postLogoutRedirectUri
    }
  },
  nativeCampaignManagement: {
    apiUrl: () => config.nativeCampaignManagement.apiUrl,
    xApiKey: () => config.nativeCampaignManagement.xApiKey
  },
  don: {
    apiUrl: () => config.don.apiUrl
  },
  edon: {
    apiUrl: () => config.edon.apiUrl
  },
  dam: {
    baseUrl: () => config.dam.baseUrl,
    apiUrl: () => config.dam.apiUrl
  },
  covers: {
    baseUrl: () => config.covers.baseUrl
  },
  antares: {
    apiUrl: () => config.antares.apiUrl,
    apiToken: () => config.antares.apiToken
  },
  eshop: {
    apiUrl: () => config.eshop.apiUrl
  },
  video: {
    apiUrl: () => config.video.apiUrl
  },
  sport24: {
    apiUrl: () => config.sport24.apiUrl
  },
  analytics: {
    apiUrl: () => config.analytics.apiUrl
  },
  notificationService: {
    apiUrl: () => config.notificationService.apiUrl
  },
  dataHub: {
    apiUrl: () => config.dataHub.apiUrl,
    xApiKey: {
      abn: () => config.dataHub.xApiKey.abn,
      realtime: () => config.dataHub.xApiKey.realtime,
      hpctr: () => config.dataHub.xApiKey.hpctr,
      video: () => config.dataHub.xApiKey.video,
      universalDashboarding: () => config.dataHub.xApiKey.universalDashboarding,
      recommend: () => config.dataHub.xApiKey.recommend.dataHub
    },
    recommend: {
      apiUrl: () => config.dataHub.recommend.apiUrl,
      xApiKey: () => config.dataHub.recommend.xApiKey
    },
    editPerf: {
      apiUrl: () => config.dataHub.editPerf.apiUrl,
      xApiKey: () => config.dataHub.editPerf.xApiKey
    }
  },
  aiService: {
    apiUrl: () => config.aiService.apiUrl,
    apiUrlV2: () => config.aiService.apiUrlV2
  },
  linkPreview: {
    apiUrl: () => config.linkPreview.apiUrl,
    apiToken: () => config.linkPreview.apiToken
  },
  eagleVideoPlayer: {
    baseUrl: () => config.eagleVideoPlayer.baseUrl
  },
  articleSetting: {
    elasticArticleListing: () => config.articleSetting.elasticArticleListing,
    articlePreviewUrl: () => config.articleSetting.articlePreviewUrl,
    articleLogInterval: () => config.articleSetting.articleLogInterval
  },
  hotjar: {
    hotjarAccountId: () => config.hotjar.hotjarAccountId
  },
  woodwingService: {
    apiUrl: () => config.woodwingService.apiUrl
  }
}
